body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "poppins", "Segoe UI",
    "Roboto", "khmer-os-battambang", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* khmer-os-battambang font*/
@font-face {
  font-family: "khmer-os-battambang";
  src: url(./fonts/khmer-os/khmer-os-battambang.ttf) format("truetype");
}

/* poppins font*/
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-Thin.ttf) format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-ThinItalic.ttf) format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-ExtraLight.ttf) format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-ExtraLightItalic.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-Light.ttf) format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-Regular.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-Medium.ttf) format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-SemiBold.ttf) format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-SemiBoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-Bold.ttf) format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-ExtraBold.ttf) format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-ExtraBoldItalic.ttf) format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-Black.ttf) format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "poppins";
  src: url(./fonts/poppins/Poppins-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
}
