@import url(https://fonts.googleapis.com/css2?family=Siemreap&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Moul&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "poppins", "Segoe UI",
    "Roboto", "khmer-os-battambang", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* khmer-os-battambang font*/
@font-face {
  font-family: "khmer-os-battambang";
  src: url(/static/media/khmer-os-battambang.36ba1bdd.ttf) format("truetype");
}

/* poppins font*/
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-Thin.59ff6a72.ttf) format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-ThinItalic.9d5e661e.ttf) format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-ExtraLight.333cb30c.ttf) format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-ExtraLightItalic.dbdfd398.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-Light.7641a0f7.ttf) format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-LightItalic.4a7d59f8.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-Regular.35d26b78.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-Italic.e373f616.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-Medium.673ed423.ttf) format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-MediumItalic.89e040c6.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-SemiBold.ac8d04b6.ttf) format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-SemiBoldItalic.3524ffc3.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-Bold.cdb29a5d.ttf) format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-BoldItalic.cfb635a5.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-ExtraBold.01849ee0.ttf) format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-ExtraBoldItalic.73d4495b.ttf) format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-Black.d8b850b9.ttf) format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "poppins";
  src: url(/static/media/Poppins-BlackItalic.563eda3d.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
}

@media print {
  body {
    margin: 0;
    box-shadow: 0;
  }
  /* .testingFooter {
    position: fixed;
    bottom: 0;
  } */
  /* .testingInvoice {
    background-color: lightblue;
    height: 773px;
  } */
  /* .testingFooter {
    left: 0;
    width: 100%;
    position: running(footer);
    height: 200px;
  } */
}
/* 
.testingFooter {
  left: 0;
  width: 100%;
  position: running(footer);
} */
/* 
.testingFooter {
  float: top;
} */

@page {
  margin: 1cm;
  size: a4;
}
/* 
#testingFooter {
  position: running(testingFooter);
} */

.react-datepicker-popper {
  z-index: 3;
}

