@import url("https://fonts.googleapis.com/css2?family=Siemreap&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Moul&display=swap");

@media print {
  body {
    margin: 0;
    box-shadow: 0;
  }
  /* .testingFooter {
    position: fixed;
    bottom: 0;
  } */
  /* .testingInvoice {
    background-color: lightblue;
    height: 773px;
  } */
  /* .testingFooter {
    left: 0;
    width: 100%;
    position: running(footer);
    height: 200px;
  } */
}
/* 
.testingFooter {
  left: 0;
  width: 100%;
  position: running(footer);
} */
/* 
.testingFooter {
  float: top;
} */

@page {
  margin: 1cm;
  size: a4;
}
/* 
#testingFooter {
  position: running(testingFooter);
} */

.react-datepicker-popper {
  z-index: 3;
}
